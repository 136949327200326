import { urlFor, pathFor } from 'webpacker-routes'
const parent_spec = null
const default_url_options = {"host":"taobao.at","protocol":"https"}
export const about_spec = ["/about(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const about_url = (...args) => urlFor(about_spec, ...args)
export const about_path = (...args) => pathFor(about_spec, ...args)
export const add_issue_spec = ["/delivery_plugin/add_issue(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const add_issue_url = (...args) => urlFor(add_issue_spec, ...args)
export const add_issue_path = (...args) => pathFor(add_issue_spec, ...args)
export const agreement_spec = ["/agreement(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const agreement_url = (...args) => urlFor(agreement_spec, ...args)
export const agreement_path = (...args) => pathFor(agreement_spec, ...args)
export const agreement_profile_spec = ["/profile/agreement(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const agreement_profile_url = (...args) => urlFor(agreement_profile_spec, ...args)
export const agreement_profile_path = (...args) => pathFor(agreement_profile_spec, ...args)
export const ban_user_spec = ["/users/:id/ban(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const ban_user_url = (...args) => urlFor(ban_user_spec, ...args)
export const ban_user_path = (...args) => pathFor(ban_user_spec, ...args)
export const binding_code_order_spec = ["/orders/:id/binding_code(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const binding_code_order_url = (...args) => urlFor(binding_code_order_spec, ...args)
export const binding_code_order_path = (...args) => pathFor(binding_code_order_spec, ...args)
export const buyout_orders_spec = ["/buyout_orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const buyout_orders_url = (...args) => urlFor(buyout_orders_spec, ...args)
export const buyout_orders_path = (...args) => pathFor(buyout_orders_spec, ...args)
export const cancel_user_registration_spec = ["/cancel(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cancel_user_registration_url = (...args) => urlFor(cancel_user_registration_spec, ...args)
export const cancel_user_registration_path = (...args) => pathFor(cancel_user_registration_spec, ...args)
export const cards_orders_spec = ["/orders/cards(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cards_orders_url = (...args) => urlFor(cards_orders_spec, ...args)
export const cards_orders_path = (...args) => pathFor(cards_orders_spec, ...args)
export const cdek_finance_plugin_notify_spec = ["/cdek_finance_plugin/notify(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_finance_plugin_notify_url = (...args) => urlFor(cdek_finance_plugin_notify_spec, ...args)
export const cdek_finance_plugin_notify_path = (...args) => pathFor(cdek_finance_plugin_notify_spec, ...args)
export const cdek_finance_plugin_payment_link_spec = ["/cdek_finance_plugin/orders/:id/payment_link(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_finance_plugin_payment_link_url = (...args) => urlFor(cdek_finance_plugin_payment_link_spec, ...args)
export const cdek_finance_plugin_payment_link_path = (...args) => pathFor(cdek_finance_plugin_payment_link_spec, ...args)
export const cdek_finance_plugin_qr_link_spec = ["/cdek_finance_plugin/orders/:id/qr_link(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_finance_plugin_qr_link_url = (...args) => urlFor(cdek_finance_plugin_qr_link_spec, ...args)
export const cdek_finance_plugin_qr_link_path = (...args) => pathFor(cdek_finance_plugin_qr_link_spec, ...args)
export const cdek_finance_plugin_return_fail_spec = ["/cdek_finance_plugin/orders/:id/return_fail(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_finance_plugin_return_fail_url = (...args) => urlFor(cdek_finance_plugin_return_fail_spec, ...args)
export const cdek_finance_plugin_return_fail_path = (...args) => pathFor(cdek_finance_plugin_return_fail_spec, ...args)
export const cdek_finance_plugin_return_success_spec = ["/cdek_finance_plugin/orders/:id/return_success(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_finance_plugin_return_success_url = (...args) => urlFor(cdek_finance_plugin_return_success_spec, ...args)
export const cdek_finance_plugin_return_success_path = (...args) => pathFor(cdek_finance_plugin_return_success_spec, ...args)
export const cdek_plugin_city_list_request_spec = ["/cdek_plugin/city_list_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_city_list_request_url = (...args) => urlFor(cdek_plugin_city_list_request_spec, ...args)
export const cdek_plugin_city_list_request_path = (...args) => pathFor(cdek_plugin_city_list_request_spec, ...args)
export const cdek_plugin_config_spec = ["/cdek_plugin/company_config(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_config_url = (...args) => urlFor(cdek_plugin_config_spec, ...args)
export const cdek_plugin_config_path = (...args) => pathFor(cdek_plugin_config_spec, ...args)
export const cdek_plugin_delivery_calculator_request_spec = ["/cdek_plugin/delivery_calculator_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_delivery_calculator_request_url = (...args) => urlFor(cdek_plugin_delivery_calculator_request_spec, ...args)
export const cdek_plugin_delivery_calculator_request_path = (...args) => pathFor(cdek_plugin_delivery_calculator_request_spec, ...args)
export const cdek_plugin_departure_city_list_request_spec = ["/cdek_plugin/departure_city_list_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_departure_city_list_request_url = (...args) => urlFor(cdek_plugin_departure_city_list_request_spec, ...args)
export const cdek_plugin_departure_city_list_request_path = (...args) => pathFor(cdek_plugin_departure_city_list_request_spec, ...args)
export const cdek_plugin_order_info_request_spec = ["/cdek_plugin/orders/:id/order_info_request(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_order_info_request_url = (...args) => urlFor(cdek_plugin_order_info_request_spec, ...args)
export const cdek_plugin_order_info_request_path = (...args) => pathFor(cdek_plugin_order_info_request_spec, ...args)
export const cdek_plugin_order_update_request_spec = ["/cdek_plugin/orders/:id/order_update_request(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_order_update_request_url = (...args) => urlFor(cdek_plugin_order_update_request_spec, ...args)
export const cdek_plugin_order_update_request_path = (...args) => pathFor(cdek_plugin_order_update_request_spec, ...args)
export const cdek_plugin_pvz_list_request_spec = ["/cdek_plugin/pvz_list_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_pvz_list_request_url = (...args) => urlFor(cdek_plugin_pvz_list_request_spec, ...args)
export const cdek_plugin_pvz_list_request_path = (...args) => pathFor(cdek_plugin_pvz_list_request_spec, ...args)
export const cdek_plugin_region_list_request_spec = ["/cdek_plugin/region_list_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_region_list_request_url = (...args) => urlFor(cdek_plugin_region_list_request_spec, ...args)
export const cdek_plugin_region_list_request_path = (...args) => pathFor(cdek_plugin_region_list_request_spec, ...args)
export const cdek_plugin_update_order_status_spec = ["/cdek_plugin/update_order_status(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_plugin_update_order_status_url = (...args) => urlFor(cdek_plugin_update_order_status_spec, ...args)
export const cdek_plugin_update_order_status_path = (...args) => pathFor(cdek_plugin_update_order_status_spec, ...args)
export const cdek_request_spec = ["/cdek_requests/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const cdek_request_url = (...args) => urlFor(cdek_request_spec, ...args)
export const cdek_request_path = (...args) => pathFor(cdek_request_spec, ...args)
export const chat_spec = ["/chats/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const chat_url = (...args) => urlFor(chat_spec, ...args)
export const chat_path = (...args) => pathFor(chat_spec, ...args)
export const chat_messages_spec = ["/chats/:chat_id/messages(.:format)", ["chat_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const chat_messages_url = (...args) => urlFor(chat_messages_spec, ...args)
export const chat_messages_path = (...args) => pathFor(chat_messages_spec, ...args)
export const chats_spec = ["/chats(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const chats_url = (...args) => urlFor(chats_spec, ...args)
export const chats_path = (...args) => pathFor(chats_spec, ...args)
export const companies_spec = ["/companies(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const companies_url = (...args) => urlFor(companies_spec, ...args)
export const companies_path = (...args) => pathFor(companies_spec, ...args)
export const company_spec = ["/companies/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const company_url = (...args) => urlFor(company_spec, ...args)
export const company_path = (...args) => pathFor(company_spec, ...args)
export const confirmation_note_spec = ["/confirmation_note(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const confirmation_note_url = (...args) => urlFor(confirmation_note_spec, ...args)
export const confirmation_note_path = (...args) => pathFor(confirmation_note_spec, ...args)
export const create_chat_order_spec = ["/orders/:id/create_chat(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const create_chat_order_url = (...args) => urlFor(create_chat_order_spec, ...args)
export const create_chat_order_path = (...args) => pathFor(create_chat_order_spec, ...args)
export const customer_applications_spec = ["/customer_applications(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const customer_applications_url = (...args) => urlFor(customer_applications_spec, ...args)
export const customer_applications_path = (...args) => pathFor(customer_applications_spec, ...args)
export const data_exchange_calculator_tariff_list_spec = ["/cdek_plugin/data_exchange/calculator_tariff_list(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_calculator_tariff_list_url = (...args) => urlFor(data_exchange_calculator_tariff_list_spec, ...args)
export const data_exchange_calculator_tariff_list_path = (...args) => pathFor(data_exchange_calculator_tariff_list_spec, ...args)
export const data_exchange_delivery_points_spec = ["/cdek_plugin/data_exchange/delivery_points(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_delivery_points_url = (...args) => urlFor(data_exchange_delivery_points_spec, ...args)
export const data_exchange_delivery_points_path = (...args) => pathFor(data_exchange_delivery_points_spec, ...args)
export const data_exchange_location_cities_spec = ["/cdek_plugin/data_exchange/location_cities(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_location_cities_url = (...args) => urlFor(data_exchange_location_cities_spec, ...args)
export const data_exchange_location_cities_path = (...args) => pathFor(data_exchange_location_cities_spec, ...args)
export const data_exchange_location_regions_spec = ["/cdek_plugin/data_exchange/location_regions(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_location_regions_url = (...args) => urlFor(data_exchange_location_regions_spec, ...args)
export const data_exchange_location_regions_path = (...args) => pathFor(data_exchange_location_regions_spec, ...args)
export const data_exchange_search_location_city_spec = ["/cdek_plugin/data_exchange/search_location_city(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_search_location_city_url = (...args) => urlFor(data_exchange_search_location_city_spec, ...args)
export const data_exchange_search_location_city_path = (...args) => pathFor(data_exchange_search_location_city_spec, ...args)
export const data_exchange_search_location_region_spec = ["/cdek_plugin/data_exchange/search_location_region(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const data_exchange_search_location_region_url = (...args) => urlFor(data_exchange_search_location_region_spec, ...args)
export const data_exchange_search_location_region_path = (...args) => pathFor(data_exchange_search_location_region_spec, ...args)
export const decline_agreement_spec = ["/decline_agreement(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const decline_agreement_url = (...args) => urlFor(decline_agreement_spec, ...args)
export const decline_agreement_path = (...args) => pathFor(decline_agreement_spec, ...args)
export const destroy_user_session_spec = ["/logout(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const destroy_user_session_url = (...args) => urlFor(destroy_user_session_spec, ...args)
export const destroy_user_session_path = (...args) => pathFor(destroy_user_session_spec, ...args)
export const duplicate_keys_localities_spec = ["/cdek_plugin/localities/duplicate_keys(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const duplicate_keys_localities_url = (...args) => urlFor(duplicate_keys_localities_spec, ...args)
export const duplicate_keys_localities_path = (...args) => pathFor(duplicate_keys_localities_spec, ...args)
export const edit_cdek_plugin_config_spec = ["/cdek_plugin/company_config/edit(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_cdek_plugin_config_url = (...args) => urlFor(edit_cdek_plugin_config_spec, ...args)
export const edit_cdek_plugin_config_path = (...args) => pathFor(edit_cdek_plugin_config_spec, ...args)
export const edit_company_spec = ["/companies/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_company_url = (...args) => urlFor(edit_company_spec, ...args)
export const edit_company_path = (...args) => pathFor(edit_company_spec, ...args)
export const edit_item_spec = ["/items/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_item_url = (...args) => urlFor(edit_item_spec, ...args)
export const edit_item_path = (...args) => pathFor(edit_item_spec, ...args)
export const edit_message_spec = ["/messages/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_message_url = (...args) => urlFor(edit_message_spec, ...args)
export const edit_message_path = (...args) => pathFor(edit_message_spec, ...args)
export const edit_profile_spec = ["/profile/edit(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_profile_url = (...args) => urlFor(edit_profile_spec, ...args)
export const edit_profile_path = (...args) => pathFor(edit_profile_spec, ...args)
export const edit_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_rails_conductor_inbound_email_url = (...args) => urlFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_rails_conductor_inbound_email_path = (...args) => pathFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_task_spec = ["/tasks/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_task_url = (...args) => urlFor(edit_task_spec, ...args)
export const edit_task_path = (...args) => pathFor(edit_task_spec, ...args)
export const edit_user_spec = ["/users/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_url = (...args) => urlFor(edit_user_spec, ...args)
export const edit_user_path = (...args) => pathFor(edit_user_spec, ...args)
export const edit_user_password_spec = ["/password/edit(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_password_url = (...args) => urlFor(edit_user_password_spec, ...args)
export const edit_user_password_path = (...args) => pathFor(edit_user_password_spec, ...args)
export const edit_user_registration_spec = ["/settings(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_registration_url = (...args) => urlFor(edit_user_registration_spec, ...args)
export const edit_user_registration_path = (...args) => pathFor(edit_user_registration_spec, ...args)
export const fake_payment_spec = ["/cdek_finance_plugin/fake_payment(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const fake_payment_url = (...args) => urlFor(fake_payment_spec, ...args)
export const fake_payment_path = (...args) => pathFor(fake_payment_spec, ...args)
export const fill_in_coordinates_locality_spec = ["/cdek_plugin/localities/:id/fill_in_coordinates(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const fill_in_coordinates_locality_url = (...args) => urlFor(fill_in_coordinates_locality_spec, ...args)
export const fill_in_coordinates_locality_path = (...args) => pathFor(fill_in_coordinates_locality_spec, ...args)
export const flow_element_spec = ["/flow_elements/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_element_url = (...args) => urlFor(flow_element_spec, ...args)
export const flow_element_path = (...args) => pathFor(flow_element_spec, ...args)
export const flow_element_tasks_spec = ["/flow_elements/:flow_element_id/tasks(.:format)", ["flow_element_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_element_tasks_url = (...args) => urlFor(flow_element_tasks_spec, ...args)
export const flow_element_tasks_path = (...args) => pathFor(flow_element_tasks_spec, ...args)
export const flow_elements_spec = ["/flow_elements(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_elements_url = (...args) => urlFor(flow_elements_spec, ...args)
export const flow_elements_path = (...args) => pathFor(flow_elements_spec, ...args)
export const generate_binding_code_order_spec = ["/orders/:id/generate_binding_code(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const generate_binding_code_order_url = (...args) => urlFor(generate_binding_code_order_spec, ...args)
export const generate_binding_code_order_path = (...args) => pathFor(generate_binding_code_order_spec, ...args)
export const hash_localities_spec = ["/cdek_plugin/localities/hash_localities/:hash(.:format)", ["hash","format"], { ...default_url_options, ...{} }, parent_spec]
export const hash_localities_url = (...args) => urlFor(hash_localities_spec, ...args)
export const hash_localities_path = (...args) => pathFor(hash_localities_spec, ...args)
export const internet_magazin_spec = ["/internet-magazin(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const internet_magazin_url = (...args) => urlFor(internet_magazin_spec, ...args)
export const internet_magazin_path = (...args) => pathFor(internet_magazin_spec, ...args)
export const item_spec = ["/items/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const item_url = (...args) => urlFor(item_spec, ...args)
export const item_path = (...args) => pathFor(item_spec, ...args)
export const make_main_locality_spec = ["/cdek_plugin/localities/:id/make_main(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const make_main_locality_url = (...args) => urlFor(make_main_locality_spec, ...args)
export const make_main_locality_path = (...args) => pathFor(make_main_locality_spec, ...args)
export const message_spec = ["/messages/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const message_url = (...args) => urlFor(message_spec, ...args)
export const message_path = (...args) => pathFor(message_spec, ...args)
export const new_chat_message_spec = ["/chats/:chat_id/messages/new(.:format)", ["chat_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const new_chat_message_url = (...args) => urlFor(new_chat_message_spec, ...args)
export const new_chat_message_path = (...args) => pathFor(new_chat_message_spec, ...args)
export const new_company_spec = ["/companies/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_company_url = (...args) => urlFor(new_company_spec, ...args)
export const new_company_path = (...args) => pathFor(new_company_spec, ...args)
export const new_order_item_spec = ["/orders/:order_id/items/new(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const new_order_item_url = (...args) => urlFor(new_order_item_spec, ...args)
export const new_order_item_path = (...args) => pathFor(new_order_item_spec, ...args)
export const new_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_url = (...args) => urlFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_path = (...args) => pathFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_source_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_source_url = (...args) => urlFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const new_rails_conductor_inbound_email_source_path = (...args) => pathFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const new_user_spec = ["/users/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_url = (...args) => urlFor(new_user_spec, ...args)
export const new_user_path = (...args) => pathFor(new_user_spec, ...args)
export const new_user_confirmation_spec = ["/confirmation/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_confirmation_url = (...args) => urlFor(new_user_confirmation_spec, ...args)
export const new_user_confirmation_path = (...args) => pathFor(new_user_confirmation_spec, ...args)
export const new_user_password_spec = ["/password/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_password_url = (...args) => urlFor(new_user_password_spec, ...args)
export const new_user_password_path = (...args) => pathFor(new_user_password_spec, ...args)
export const new_user_registration_spec = ["/register(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_registration_url = (...args) => urlFor(new_user_registration_spec, ...args)
export const new_user_registration_path = (...args) => pathFor(new_user_registration_spec, ...args)
export const new_user_session_spec = ["/login(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_session_url = (...args) => urlFor(new_user_session_spec, ...args)
export const new_user_session_path = (...args) => pathFor(new_user_session_spec, ...args)
export const new_wx_plugin_mp_code_request_spec = ["/wx_plugin/mp_code_request/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_wx_plugin_mp_code_request_url = (...args) => urlFor(new_wx_plugin_mp_code_request_spec, ...args)
export const new_wx_plugin_mp_code_request_path = (...args) => pathFor(new_wx_plugin_mp_code_request_spec, ...args)
export const order_spec = ["/orders/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_url = (...args) => urlFor(order_spec, ...args)
export const order_path = (...args) => pathFor(order_spec, ...args)
export const order_item_spec = ["/orders/:order_id/item(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_item_url = (...args) => urlFor(order_item_spec, ...args)
export const order_item_path = (...args) => pathFor(order_item_spec, ...args)
export const order_items_spec = ["/orders/:order_id/items(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_items_url = (...args) => urlFor(order_items_spec, ...args)
export const order_items_path = (...args) => pathFor(order_items_spec, ...args)
export const orders_spec = ["/orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const orders_url = (...args) => urlFor(orders_spec, ...args)
export const orders_path = (...args) => pathFor(orders_spec, ...args)
export const profile_spec = ["/profile(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const profile_url = (...args) => urlFor(profile_spec, ...args)
export const profile_path = (...args) => pathFor(profile_spec, ...args)
export const progress_order_spec = ["/orders/:id/progress(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const progress_order_url = (...args) => urlFor(progress_order_spec, ...args)
export const progress_order_path = (...args) => pathFor(progress_order_spec, ...args)
export const rails_blob_representation_spec = ["/rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_url = (...args) => urlFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_path = (...args) => pathFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_proxy_spec = ["/rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_proxy_url = (...args) => urlFor(rails_blob_representation_proxy_spec, ...args)
export const rails_blob_representation_proxy_path = (...args) => pathFor(rails_blob_representation_proxy_spec, ...args)
export const rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_url = (...args) => urlFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_path = (...args) => pathFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_reroute_spec = ["/rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)", ["inbound_email_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_reroute_url = (...args) => urlFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_reroute_path = (...args) => pathFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_sources_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_sources_url = (...args) => urlFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_email_sources_path = (...args) => pathFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_emails_spec = ["/rails/conductor/action_mailbox/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_emails_url = (...args) => urlFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_conductor_inbound_emails_path = (...args) => pathFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_direct_uploads_spec = ["/rails/active_storage/direct_uploads(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_direct_uploads_url = (...args) => urlFor(rails_direct_uploads_spec, ...args)
export const rails_direct_uploads_path = (...args) => pathFor(rails_direct_uploads_spec, ...args)
export const rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_key/*filename(.:format)", ["encoded_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_disk_service_url = (...args) => urlFor(rails_disk_service_spec, ...args)
export const rails_disk_service_path = (...args) => pathFor(rails_disk_service_spec, ...args)
export const rails_mailgun_inbound_emails_spec = ["/rails/action_mailbox/mailgun/inbound_emails/mime(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mailgun_inbound_emails_url = (...args) => urlFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mailgun_inbound_emails_path = (...args) => pathFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_emails_url = (...args) => urlFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_path = (...args) => pathFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_health_check_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_health_check_url = (...args) => urlFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_mandrill_inbound_health_check_path = (...args) => pathFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_postmark_inbound_emails_spec = ["/rails/action_mailbox/postmark/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_postmark_inbound_emails_url = (...args) => urlFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_postmark_inbound_emails_path = (...args) => pathFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_spec = ["/rails/action_mailbox/relay/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_relay_inbound_emails_url = (...args) => urlFor(rails_relay_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_path = (...args) => pathFor(rails_relay_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_spec = ["/rails/action_mailbox/sendgrid/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_sendgrid_inbound_emails_url = (...args) => urlFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_path = (...args) => pathFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_service_blob_spec = ["/rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_url = (...args) => urlFor(rails_service_blob_spec, ...args)
export const rails_service_blob_path = (...args) => pathFor(rails_service_blob_spec, ...args)
export const rails_service_blob_proxy_spec = ["/rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_proxy_url = (...args) => urlFor(rails_service_blob_proxy_spec, ...args)
export const rails_service_blob_proxy_path = (...args) => pathFor(rails_service_blob_proxy_spec, ...args)
export const root_spec = ["/", [], { ...default_url_options, ...{} }, parent_spec]
export const root_url = (...args) => urlFor(root_spec, ...args)
export const root_path = (...args) => pathFor(root_spec, ...args)
export const settings_spec = ["/settings(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const settings_url = (...args) => urlFor(settings_spec, ...args)
export const settings_path = (...args) => pathFor(settings_spec, ...args)
export const success_order_spec = ["/orders/:id/success(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const success_order_url = (...args) => urlFor(success_order_spec, ...args)
export const success_order_path = (...args) => pathFor(success_order_spec, ...args)
export const success_payment_order_spec = ["/orders/:id/success_payment(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const success_payment_order_url = (...args) => urlFor(success_payment_order_spec, ...args)
export const success_payment_order_path = (...args) => pathFor(success_payment_order_spec, ...args)
export const summary_localities_spec = ["/cdek_plugin/localities/summary(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const summary_localities_url = (...args) => urlFor(summary_localities_spec, ...args)
export const summary_localities_path = (...args) => pathFor(summary_localities_spec, ...args)
export const sync_with_delivery_order_spec = ["/orders/:id/sync_with_delivery(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const sync_with_delivery_order_url = (...args) => urlFor(sync_with_delivery_order_spec, ...args)
export const sync_with_delivery_order_path = (...args) => pathFor(sync_with_delivery_order_spec, ...args)
export const task_spec = ["/tasks/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const task_url = (...args) => urlFor(task_spec, ...args)
export const task_path = (...args) => pathFor(task_spec, ...args)
export const task_adapters_buyout_new_order_spec = ["/task_adapters/buyout_new_order(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const task_adapters_buyout_new_order_url = (...args) => urlFor(task_adapters_buyout_new_order_spec, ...args)
export const task_adapters_buyout_new_order_path = (...args) => pathFor(task_adapters_buyout_new_order_spec, ...args)
export const track_orders_spec = ["/orders/track(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const track_orders_url = (...args) => urlFor(track_orders_spec, ...args)
export const track_orders_path = (...args) => pathFor(track_orders_spec, ...args)
export const tracking_companies_spec = ["/tracking_companies(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const tracking_companies_url = (...args) => urlFor(tracking_companies_spec, ...args)
export const tracking_companies_path = (...args) => pathFor(tracking_companies_spec, ...args)
export const unban_user_spec = ["/users/:id/unban(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const unban_user_url = (...args) => urlFor(unban_user_spec, ...args)
export const unban_user_path = (...args) => pathFor(unban_user_spec, ...args)
export const unread_chats_spec = ["/chats/unread(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const unread_chats_url = (...args) => urlFor(unread_chats_spec, ...args)
export const unread_chats_path = (...args) => pathFor(unread_chats_spec, ...args)
export const update_from_coordinates_locality_spec = ["/cdek_plugin/localities/:id/update_from_coordinates(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_from_coordinates_locality_url = (...args) => urlFor(update_from_coordinates_locality_spec, ...args)
export const update_from_coordinates_locality_path = (...args) => pathFor(update_from_coordinates_locality_spec, ...args)
export const update_from_name_locality_spec = ["/cdek_plugin/localities/:id/update_from_name(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_from_name_locality_url = (...args) => urlFor(update_from_name_locality_spec, ...args)
export const update_from_name_locality_path = (...args) => pathFor(update_from_name_locality_spec, ...args)
export const update_hash_locality_spec = ["/cdek_plugin/localities/:id/update_hash(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_hash_locality_url = (...args) => urlFor(update_hash_locality_spec, ...args)
export const update_hash_locality_path = (...args) => pathFor(update_hash_locality_spec, ...args)
export const update_rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_token(.:format)", ["encoded_token","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_rails_disk_service_url = (...args) => urlFor(update_rails_disk_service_spec, ...args)
export const update_rails_disk_service_path = (...args) => pathFor(update_rails_disk_service_spec, ...args)
export const update_state_spec = ["/delivery_plugin/update_state(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const update_state_url = (...args) => urlFor(update_state_spec, ...args)
export const update_state_path = (...args) => pathFor(update_state_spec, ...args)
export const update_webpush_params_spec = ["/update_webpush_params(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const update_webpush_params_url = (...args) => urlFor(update_webpush_params_spec, ...args)
export const update_webpush_params_path = (...args) => pathFor(update_webpush_params_spec, ...args)
export const user_spec = ["/users/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const user_url = (...args) => urlFor(user_spec, ...args)
export const user_path = (...args) => pathFor(user_spec, ...args)
export const user_confirmation_spec = ["/confirmation(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_confirmation_url = (...args) => urlFor(user_confirmation_spec, ...args)
export const user_confirmation_path = (...args) => pathFor(user_confirmation_spec, ...args)
export const user_orders_spec = ["/user_orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_orders_url = (...args) => urlFor(user_orders_spec, ...args)
export const user_orders_path = (...args) => pathFor(user_orders_spec, ...args)
export const user_password_spec = ["/password(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_password_url = (...args) => urlFor(user_password_spec, ...args)
export const user_password_path = (...args) => pathFor(user_password_spec, ...args)
export const user_registration_spec = ["/", [], { ...default_url_options, ...{} }, parent_spec]
export const user_registration_url = (...args) => urlFor(user_registration_spec, ...args)
export const user_registration_path = (...args) => pathFor(user_registration_spec, ...args)
export const user_session_spec = ["/login(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_session_url = (...args) => urlFor(user_session_spec, ...args)
export const user_session_path = (...args) => pathFor(user_session_spec, ...args)
export const users_spec = ["/users(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const users_url = (...args) => urlFor(users_spec, ...args)
export const users_path = (...args) => pathFor(users_spec, ...args)
export const was_active_users_spec = ["/users/was_active(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const was_active_users_url = (...args) => urlFor(was_active_users_spec, ...args)
export const was_active_users_path = (...args) => pathFor(was_active_users_spec, ...args)
export const wx_plugin_mp_code_request_spec = ["/wx_plugin/mp_code_request(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const wx_plugin_mp_code_request_url = (...args) => urlFor(wx_plugin_mp_code_request_spec, ...args)
export const wx_plugin_mp_code_request_path = (...args) => pathFor(wx_plugin_mp_code_request_spec, ...args)
